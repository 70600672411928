import PropTypes from 'prop-types';

export default PropTypes.shape({
  excerpt: PropTypes.string,
  fields: PropTypes.shape({
    slug: PropTypes.string,
  }),
  frontmatter: PropTypes.shape({
    date: PropTypes.string,
    private: PropTypes.bool,
    title: PropTypes.string,
  }),
});
