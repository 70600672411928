import React from 'react';

export default function SignUpForm () {
  return (
    <form
      action="https://seniorskills.us19.list-manage.com/subscribe/post?u=c0c17eb9d9bd58587d1ac73d8&amp;id=ff0f10fa7d"
      id="mc-embedded-subscribe-form"
      method="post"
      name="mc-embedded-subscribe-form"
      noValidate
      target="_blank"
      style={{ margin: '0 auto', maxWidth: '20rem' }}
    >
      <div className="sk-form-field__row sk-flex__justify__center">
        <div className="sk-form-field">
          <input
            type="email"
            name="EMAIL"
            className="email"
            id="mce-EMAIL"
            placeholder="email address"
            required
          />
        </div>
        <div className="sk-form-field sk-form-field__button">
          <input
            type="submit"
            value="Subscribe"
            name="subscribe"
            id="mc-embedded-subscribe"
            className="sk-button"
          />
        </div>
      </div>
      <div style={{ position: 'absolute', left: -5000 }} aria-hidden="true">
        <input
          type="text"
          name="b_c0c17eb9d9bd58587d1ac73d8_ff0f10fa7d"
          tabIndex="-1"
        />
      </div>
    </form>
  );
}
