import React from 'react';
import { Link } from 'gatsby';
import _ from 'lodash';
import ShapePost from '../shapes/post';

export default function FeaturedPost ({ post }) {
  const title = _.get(post, 'frontmatter.title') || _.get(post, 'fields.slug') || 'N/A';

  return (
    <div className="sk-post sk-post__featured">
      <h4>{ title }</h4>
      <p>{ post.excerpt }</p>
      <ul className="sk-post__actions">
        <li>
          <Link to={ _.get(post, 'fields.slug') }>
            Read More
          </Link>
        </li>
      </ul>
    </div>
  );
}

FeaturedPost.propTypes = {
  post: ShapePost.isRequired,
};
