import React from 'react';
import SignUpForm from './SignUpForm';
import fixtureBanner from '../fixtures/banner.json';

const Banner = () => {
  return (
    <section className="sk-section sk-center__vertical sk-top-lines-4 sk-text__center">
      <div className="sk-container">
        <h2>{fixtureBanner.title}</h2>
        <h5 className="sk-top-lines-1">{fixtureBanner.slogan}</h5>

        <div className="sk-top-lines-2">
          <SignUpForm />
        </div>
      </div>
    </section>
  );
};

export default Banner;
