import _ from 'lodash';
import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

export default (WrappedComponent) => {
  return function injectSiteMetadata (props) {
    return (
      <StaticQuery
        query={
          graphql`
            query {
              siteMetadata: site {
                siteMetadata {
                  title
                  description
                  siteUrl
                }
              }
            }
          `
        }
        render={
          (data) => {
            const siteMetadata = _.get(data, 'siteMetadata.siteMetadata', {});
            return (
              <WrappedComponent
                siteMetadata={ siteMetadata }
                { ...props }
              />
            );
          }
        }
      />
    );
  };
};
