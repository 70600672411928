import React from 'react';
import { Link } from 'gatsby';

export default function StylizedLogo () {
  return (
    <Link to="/" className="sk-logo">
      <span className="sk-primary">&#123;</span>
      <span>SeniorSkills</span>
      <span className="sk-primary">&#125;</span>
    </Link>
  );
}
