import React from 'react';
import PropTypes from 'prop-types';
import StylizedLogo from './StylizedLogo';
import shapeSiteMetadata from '../shapes/siteMetadata';

const Header = () => {
  return (
    <header id="header">
      <div className="sk-app-header">
        <div className="sk-container sk-text__center">
          <h4><StylizedLogo /></h4>
        </div>
      </div>
    </header>
  );
};

Header.propTypes = {
  location: PropTypes.object.isRequired,
  siteMetadata: shapeSiteMetadata.isRequired,
};

export default Header;
