import _ from 'lodash';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import SiteMetadataWrapper from '../components/SiteMetadataWrapper';
import Layout from '../components/Layout';
import Banner from '../components/Banner';
import FeaturedContent from '../components/FeaturedContent';
import shapeSiteMetadata from '../shapes/siteMetadata';

class SiteIndex extends PureComponent {
  static propTypes = {
    location: PropTypes.object.isRequired,
    siteMetadata: shapeSiteMetadata.isRequired,
    data: PropTypes.object.isRequired,
  };

  componentDidMount () {
    window.document.body.className = 'landing';
  }

  render () {
    const {
      location,
      siteMetadata,
    } = this.props;
    const posts = _.chain(this.props)
      .get('data.posts.edges', [])
      .filter(post => !_.get(post, 'node.frontmatter.private', false))
      .value();
    return (
      <Layout
        location={ location }
        siteMetadata={ siteMetadata }
      >
        <Banner />
        <FeaturedContent posts={ posts } />
      </Layout>
    );
  }
}

export default SiteMetadataWrapper(SiteIndex);

export const pageQuery = graphql`
  query {
    posts: allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt(pruneLength: 140)
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            private
          }
        }
      }
    }
  }
`;
