import React from 'react';
import StylizedLogo from './StylizedLogo';
import SignUpForm from './SignUpForm';

const CallToAction = () => {
  return (
    <section className="sk-section sk-text__center" id="cta">
      <div className="sk-container">
        <h3>
          { 'Get ' }
          <StylizedLogo />
          { ' in your inbox' }
        </h3>
        <p>Learn how to become a Senior Developer in just 50 days</p>
        <SignUpForm />
      </div>
    </section>
  );
};

export default CallToAction;
