import React from 'react';
import PropTypes from 'prop-types';
import CallToAction from './CallToAction';
import shapeSiteMetadata from '../shapes/siteMetadata';

const Footer = ({ siteMetadata }) => {
  return (
    <footer className="sk-footer">
      <CallToAction />
      <div className="sk-container">
        <div className="sk-footer__content">
          <div>
            &copy;
            { ` ${new Date().getFullYear()} ${siteMetadata.title}` }
          </div>
          <div>
            <a href={ `mailto:${siteMetadata.emailSupport}` }>
              { siteMetadata.emailSupport }
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

Footer.propTypes = {
  location: PropTypes.object.isRequired,
  siteMetadata: shapeSiteMetadata.isRequired,
};

export default Footer;
