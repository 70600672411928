import React from 'react';
import PropTypes from 'prop-types';

export default function Emoji ({ character, label }) {
  return (
    <span
      aria-label={ label }
      className="sk-emoji"
      role="img"
    >
      { character }
    </span>
  );
}

Emoji.propTypes = {
  character: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};
