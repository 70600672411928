import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import Header from './Header';
import Footer from './Footer';
import shapeChildren from '../shapes/children';
import shapeSiteMetadata from '../shapes/siteMetadata';

import 'prismjs';
import 'prismjs/themes/prism-okaidia.css';
import '../styles/main.scss';

const Layout = ({
  location,
  siteMetadata,
  pageDescription,
  pageTitle,
  isBlogPost,
  isPrivate,
  children,
}) => {
  const title = pageTitle || siteMetadata.title;
  const description = pageDescription || siteMetadata.description;
  const pageUrl = `${siteMetadata.siteUrl}${location.pathname}`;
  return (
    <div className="sk-app-container">
      <Helmet>
        { /* General tags */ }
        <html lang="en" />
        <title>{ title }</title>
        <meta name="description" content={ description } />
        <link rel="canonical" href={ pageUrl } />
        {
          isPrivate ? <meta name="robots" content="noindex, nofollow" />
            : <meta name="robots" content="index, follow" />
        }

        { /* OpenGraph tags */ }
        { isBlogPost && <meta property="og:type" content="article" /> }
        <meta property="og:url" content={ pageUrl } />
        <meta property="og:title" content={ title } />
        <meta property="og:description" content={ description } />

        { /* Twitter Card tags */ }
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content={ siteMetadata.author } />
        <meta name="twitter:title" content={ title } />
        <meta name="twitter:description" content={ description } />
      </Helmet>
      <Header
        location={ location }
        siteMetadata={ siteMetadata }
      />
      { children }
      <Footer
        location={ location }
        siteMetadata={ siteMetadata }
      />
    </div>
  );
};

Layout.defaultProps = {
  pageDescription: null,
  pageTitle: null,
  isBlogPost: false,
  isPrivate: false,
};

Layout.propTypes = {
  location: PropTypes.object.isRequired,
  pageDescription: PropTypes.string,
  pageTitle: PropTypes.string,
  siteMetadata: shapeSiteMetadata.isRequired,
  isBlogPost: PropTypes.bool,
  isPrivate: PropTypes.bool,
  children: shapeChildren.isRequired,
};

export default Layout;
