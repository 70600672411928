import _ from 'lodash';
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Emoji from './Emoji';
import FeaturedPost from './FeaturedPost';
import fixtureFeaturedContent from '../fixtures/featured-content.json';
import fixtureFeatures from '../fixtures/features.json';

function renderFeatures () {
  return fixtureFeatures.map(feature => (
    <div key={ `features::${feature.key}` }>
      <Emoji character={ feature.icon.character } label={ feature.icon.description } />

      <h4>{feature.title}</h4>
      <p>{feature.content}</p>
    </div>
  ));
}

function renderFeaturedPosts (posts) {
  return _.chain(posts)
    .filter(data => _.get(data, 'node.excerpt', '').length > 0)
    .take(2)
    .map((data) => {
      const post = data.node;
      return (
        <FeaturedPost
          key={ `home::featured-posts::${_.get(post, 'fields.slug')}` }
          post={ post }
        />
      );
    })
    .value();
}

const FeaturedContent = ({ posts }) => {
  return (
    <Fragment>
      <section className="sk-section" id="features">
        <div className="sk-container">
          <div className="sk-grid sk-phone-1-col sk-tablet-2-cols">
            {renderFeatures()}
          </div>
        </div>
      </section>
      <section className="sk-section" id="features">
        <div className="sk-container">
          <h4 className="sk-heading">{fixtureFeaturedContent.sectionTitle}</h4>
          <div className="sk-grid sk-phone-1-col sk-tablet-2-cols">
            { renderFeaturedPosts(posts) }
          </div>
        </div>
      </section>
    </Fragment>
  );
};

FeaturedContent.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default FeaturedContent;
